import {
  FunctionComponent,
  ReactNode,
  createContext,
  useEffect,
  useState,
} from "react";
import {
  cancelStripeSubscription,
  getStripeSubscription,
} from "src/services/payments.service";
import { getGenerationsCount } from "src/services/users.service";

// Define the shape of the subscription information and context
interface SubscriptionInfo {
  plan: string;
  cycle: string;
  team_members: number;
  next_billing: string;
  status: string;
  // ... add other fields as necessary
}

interface SubscriptionContextProps {
  subscriptionInfo: SubscriptionInfo | null;
  fetchSubscriptionInfo: () => Promise<void>;
  refetchGenerationsCount: () => void;
  cancelSubscription: () => Promise<void>;
  generationsCount: number; // Add this line
  loading: boolean;
}

// Create the context with default values
const SubscriptionContext = createContext<SubscriptionContextProps>({
  subscriptionInfo: null,
  fetchSubscriptionInfo: async () => {},
  cancelSubscription: async () => {},
  refetchGenerationsCount: () => {},
  generationsCount: 0, // Add this line
  loading: false,
});

// Provider component
const SubscriptionProvider: FunctionComponent<{ children: ReactNode }> = ({
  children,
}) => {
  const [subscriptionInfo, setSubscriptionInfo] =
    useState<SubscriptionInfo | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [generationsCount, setGenerationsCount] = useState<number>(0); // Add state for generationsCount
  // console.log("GENERATIONS COUNT", generationsCount);
  const fetchSubscriptionInfo = async () => {
    setLoading(true);
    try {
      const response = await getStripeSubscription({});
      if (response && response.data) {
        setSubscriptionInfo({
          plan: response.data.selected_plan,
          cycle: response.data.selected_cycle,
          team_members: response.data?.team_members,
          next_billing: response.data.current_term_end,
          status: response.data.status,
        });
        setLoading(false);
      }
    } catch (error) {      
      console.error("Error fetching subscription information:", error);
    }
  };

  const cancelSubscription = async () => {
    try {
      const response = await cancelStripeSubscription({});
      const data = response.data;
      if (data.message) {
        // console.log(data.message);
        fetchSubscriptionInfo();
      } else {
        throw new Error(data.error);
      }
    } catch (error) {
      console.error("Error cancelling subscription:", error);
    }
  };

  const fetchGenerationsCount = async () => {
    try {
      const response = await getGenerationsCount();
      if (response && response.data) {
        setGenerationsCount(response.data.generated); // Set the count
      }
    } catch (error) {
      console.error("Error fetching generations count:", error);
    }
  };

  useEffect(() => {
    fetchSubscriptionInfo();
    fetchGenerationsCount();
  }, []);

  const refetchGenerationsCount = () => {
    fetchGenerationsCount();
  };

  return (
    <SubscriptionContext.Provider
      value={{
        subscriptionInfo,
        fetchSubscriptionInfo,
        cancelSubscription,
        refetchGenerationsCount,
        loading,
        generationsCount,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export { SubscriptionContext, SubscriptionProvider };
