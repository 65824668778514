import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./Header";

const PublicLayout = () => {
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const isPricingPage = location.pathname.includes("pricing");

  useEffect(() => {
    const handleScroll = () => {
      // Check if 'window' exists and has 'scrollY'
      if (typeof window !== "undefined" && "scrollY" in window) {
        const scrollY = window.scrollY;
        setScrolled(scrollY > 20);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      {!isPricingPage ? (
        <>
          {" "}
          <Header scrolled={scrolled} />
          <Box component="main" sx={{ p: 0 }}>
            {/* <Toolbar /> */}
            <Outlet />
          </Box>{" "}
        </>
      ) : (
        <Outlet />
      )}
      {/*<Footer />*/}
    </>
  );
};

export default PublicLayout;
