import { useSelector } from "react-redux";

import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import { useAuthentication } from "./hooks/useAuthentication";
// routing
import Routes from "./routes";

// defaultTheme
import themes from "./themes";

// project imports
import logo from "./assets/images/logos/callsine-logo-white-only.png";
import NavigationScroll from "./layout/NavigationScroll";

//Redux actions
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { TourProvider } from "./providers/tourprovider";
import { store } from "./store";
import { getUnreadReplies } from "./store/emailReplyCount/actions";

const isProduction = process.env.NODE_ENV === "production";

if (isProduction) {
  Sentry.init({
    dsn: "https://b0f2b455c1bcf1aff142c54773fae218@o4506411926552576.ingest.sentry.io/4506411931009024",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          "localhost",
          /^https:\/\/yourserver\.io\/api/,
        ],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
const App = () => {
  const customization = useSelector((state: any) => state.customization);

  const { isAuthenticated } = useAuthentication();

  useEffect(() => {
    let intervalId: any;

    if (isAuthenticated) {
      // Dispatch the action initially and then set up the interval
      store.dispatch(getUnreadReplies());

      intervalId = setInterval(() => {
        store.dispatch(getUnreadReplies());
      }, 120000); // 10000 milliseconds = 10 seconds
    }

    // Cleanup function to clear the interval when the component unmounts or isAuthenticated changes
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isAuthenticated, store.dispatch]);

  const style = { darkMode: false, accentColor: "#cccc", zIndex: "1000" };

  const floatingButtonStyle = {
    color: "#fff",
    backgroundColor: "#1976d2",
  };

  const icon = <img src={logo} />;
  const currentRoute = window.location.pathname;

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <TourProvider>
          {/* <MendableChatBubble
            dialogPlaceholder="Anything you want to know about Callsine, at your fingertips."
            style={style}
            icon={icon}
            floatingButtonStyle={floatingButtonStyle}
            anon_key={"8fb50d66-58ba-40f6-86fb-ab2caeb2911e"}
            cmdShortcutKey={"k"}
            welcomeMessage={"Welcome to the future, how can I help you?"}
            messageSettings={{ hideSources: true }}
          /> */}

          <CssBaseline />
          <NavigationScroll>
            <Routes />
          </NavigationScroll>
        </TourProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
