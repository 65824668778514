// action - customization reducer
export const SET_MENU = "@customization/SET_MENU";
export const MENU_TOGGLE = "@customization/MENU_TOGGLE";
export const MENU_OPEN = "@customization/MENU_OPEN";
export const SET_FONT_FAMILY = "@customization/SET_FONT_FAMILY";
export const SET_BORDER_RADIUS = "@customization/SET_BORDER_RADIUS";
export const setPlaybook = (payload: any) => ({
  type: "SET_PLAYBOOK",
  payload,
});
export const setWebSocketMessage = (payload: any) => ({
  type: "SET_WEB_SOCKET_MESSAGE",
  payload,
});
