export const endpoints = {
  LOGIN: "/token/",
  SIGNUP: "/rest-auth/registration/",
  PROFILE: "/api/me/",
  USERS_ME: "/api/users/",
  PERSON: "/api/persons/",
  PERSON_PROCESSEMAILTHREADS: "/api/process-email-threads/",
  COUNT_PERSON: "api/count-persons/",
  MAIL_ACCOUNT: "/api/mail-accounts/",
  PROMPTS: "/api/prompts/",
  PLAYBOOKS: "/api/playbooks/",
  PLAYBOOKSV2: "/api/playbooksv2/",
  DELETE_PLAYBOOK: "/api/delete_playbook/",
  PROMPT_RESPONSES: "/api/prompt-responses/",
  EMAILS: "/api/emails/",
  DRAFTEMAILS: "/api/draft-email/",
  ORGS: "/api/orgs/",
  IMPORT_PEOPLE: "/api/bulk-import/",
  SEQUENCE_EVENTS: "/api/sequence-events/",
  USER_DATA: "/api/user-data/",
  TEAMS: "/api/teams/",
  NEWPROMPT: "/api/new-prompt/",
  GOOGLEAUTH: "/api/google-auth/",
  MICROSOFTAUTH: "/api/microsoft-auth/",
  TEAM_MEMBERS: "/api/team-users/",
  API_GENERAL: "/api/",
  SENDMAIL: "/api/send-mail/",
  EMAILS_THREAD: "/api/email-thread/",
  GMAIL_THREAD: "/api/gmail-thread/",
  OUTLOOK_THREAD: "/api/outlook-thread/",
  PAYMENTS: "/payments/",
  TEAM_MEMBERS_ASSIGN: "/api/team-members/",
  UNREAD_REPLIES: "/api/unread-replies/",
  REPLY_COUNTS: "/api/reply-counts/",
  PASSWORD_RESET: "send-reset-email/",
  PASSWORD_CONFIRM: "reset-password/",
  GET_GENERATIONS_COUNT: "/api/day-generations/",
  UNSUBSCRIBE: "/api/unsubscribe/",
  GENERATE_PLAYBOOK: "/api/generate_playbook/",
  UPDATE_PERSON: "/api/update_person/",
  ADD_SITES: "/api/add-sites/",
  TEST_GENERATION: "/api/test-generate/",
  TAG: "/api/tags/",
};
