import PropTypes, { InferProps } from "prop-types";

// material-ui
import { Avatar, Box, ButtonBase, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// project imports
import ProfileSection from "./ProfileSection";

// assets
import { IconMenu2 } from "@tabler/icons-react";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { SubscriptionContext } from "src/providers/subscriptionprovider";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const HeaderPropTypes = {
  handleLeftDrawerToggle: PropTypes.func,
};

type HeaderTypes = InferProps<typeof HeaderPropTypes>;

const Header = ({ handleLeftDrawerToggle }: HeaderTypes) => {
  const theme: any = useTheme();
  const { subscriptionInfo, generationsCount } =
    useContext(SubscriptionContext);
  const location = useLocation();
  const isPeoplePage = location.pathname.includes("people");

  return (
    <Grid container display={"flex"} flexDirection={"column"} bgcolor={"#334155"}>
      {/* logo & toggler button */}
      {subscriptionInfo?.plan === "free" && (
        <Grid
          container
          display={"flex"}
          flexDirection={"row"}
          bgcolor={"#334155"}
          padding={2}
          borderRadius={2}
          justifyContent={"space-between"}
        >
          <Typography color="white">
            {isPeoplePage
              ? `You have generations ${Number(
                  10 - generationsCount
                )} remaining for today. Upgrade to get unlimited generations.`
              : "You are currently using a free account. Upgrade to get the full power of CallSine including unlimited email generations and use of our best generation engines."}
          </Typography>
          <Typography color="white" fontWeight={1000}>
            <a href="/wizard/upgrade">Upgrade Now</a>
          </Typography>
        </Grid>
      )}
      <Grid
        container
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Box
          sx={{
            width: 228,
            display: "flex",
            [theme.breakpoints.down("md")]: {
              width: "auto",
            },
          }}
        >
          {/*<Box
          component="span"
          sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}
        >
          <LogoSection />
        </Box>*/}
          <ButtonBase
            // className="md:tw-relative md:tw-left-12"
            sx={{ borderRadius: "12px", overflow: "hidden" }}
          >
            {/*@ts-ignore*/}
            <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                transition: "all .2s ease-in-out",
                background: "#334155", //theme.palette.secondary.light,
                // color: theme.palette.primary.main, //theme.palette.secondary.dark,
                color: "#fff",
                "&:hover": {
                  // background: theme.palette.secondary.dark,
                  // color: theme.palette.secondary.light,
                },
              }}
              onClick={handleLeftDrawerToggle}
              color="inherit"
            >
              <IconMenu2 stroke={2} size="1.3rem" />
            </Avatar>
          </ButtonBase>
        </Box>

        {/* header search */}
        {/*<SearchSection />*/}
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ flexGrow: 1 }} />

        {/* notification & profile */}
        {/*<NotificationSection />*/}
        <ProfileSection />
      </Grid>
    </Grid>
  );
};

Header.propTypes = HeaderPropTypes;

export default Header;
