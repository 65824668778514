import { ReactNode, createContext, useEffect, useMemo, useState } from "react";
import ReconnectingWebSocket from "reconnecting-websocket";

interface WebsocketContextType {
  socket: ReconnectingWebSocket;
  responsePayload: any; // Include responsePayload
}

const WebsocketContext = createContext<WebsocketContextType | null>(null);

interface WebsocketProviderProps {
  children: ReactNode;
  userId: string;
}

const useWebsocket = (id: string) => {
  const isProduction = process.env.NODE_ENV === "production";
  const socketUrl = isProduction
    ? `wss://api.callsine.com/ws/users/${id}/`
    : `ws://localhost:8000/ws/users/${id}/`;

  const socket = useMemo(
    () => new ReconnectingWebSocket(socketUrl),
    [socketUrl]
  );

  const [responsePayload, setResponsePayload] = useState<any>(null);

  useEffect(() => {
    const openHandler = () => {
      console.log("WebSocket connected");
    };

    const messageHandler = (event: any) => {
      console.log("Message received from WebSocket");
      const messageData = JSON.parse(event.data);
      setResponsePayload(messageData);
    };

    const errorHandler = (event: any) => {
      console.error("WebSocket error:", event);
    };

    const closeHandler = () => {
      console.log("WebSocket disconnected");
    };

    socket.addEventListener("open", openHandler);
    socket.addEventListener("message", messageHandler);
    socket.addEventListener("error", errorHandler);
    socket.addEventListener("close", closeHandler);

    return () => {
      socket.removeEventListener("open", openHandler);
      socket.removeEventListener("message", messageHandler);
      socket.removeEventListener("error", errorHandler);
      socket.removeEventListener("close", closeHandler);
      socket.close();
    };
  }, [socket]);

  return {
    socket,
    responsePayload,
  };
};

const WebsocketProvider: React.FC<WebsocketProviderProps> = ({
  children,
  userId,
}) => {
  const contextValue = useWebsocket(userId);

  return (
    <WebsocketContext.Provider value={contextValue}>
      {children}
    </WebsocketContext.Provider>
  );
};

export default WebsocketProvider;
export { WebsocketContext };
