import http from "./axios";
import { endpoints } from "./endpoints";

export const profileService = async () => {
  return await http.get(`${endpoints.PROFILE}`);
};

export const getUsersMeService = async () => {
  return await http.get(`${endpoints.USERS_ME}`);
};

export const patchUsersMeService = async (data: any) => {
  return await http.post(`${endpoints.PROFILE}`, data);
};

export const postUsersMeService = async (data: any) => {
  return await http.post(`${endpoints.USERS_ME}`, data);
};

export const getTeamMeService = async () => {
  return await http.get(`${endpoints.TEAMS}`);
};

export const updateTeamMeService = async (data: any) => {
  return await http.post(`${endpoints.TEAMS}update-team/`, data);
};

// Fetch all tags
export const getTagsService = async () => {
  return await http.get(endpoints.TAG);
};

// Create a new tag
export const createTagService = async (data: any) => {
  return await http.post(endpoints.TAG, data);
};

// Delete a tag
export const deleteTagService = async (tagId: any) => {
  return await http.delete(`${endpoints.TAG}${tagId}/`);
};

// Fetch tags with filtering by team_id (if needed)
export const getTagsByTeamIdService = async (teamId: any) => {
  return await http.get(`${endpoints.TAG}?team_id=${teamId}`);
};
